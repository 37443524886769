import { render, staticRenderFns } from "./brushData.vue?vue&type=template&id=22f3880c&scoped=true"
import script from "./brushData.vue?vue&type=script&lang=js"
export * from "./brushData.vue?vue&type=script&lang=js"
import style0 from "./brushData.vue?vue&type=style&index=0&id=22f3880c&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22f3880c",
  null
  
)

export default component.exports